import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Equip from './ui/Equip/Equip';
import Champions from './ui/Champions';
import "./ui/styles/Root.css";
import {Howl, Howler} from 'howler';

console.log("*******GET SOUND*********");
let sound = new Howl({
  src: ['/assets/audio/heckin_loading_final_version-1.mp3'],
  volume: 1,
  autoplay: true,
  loop: true
});

document.body.addEventListener('click', () => {
  if(!sound.playing()){
    sound.play()
  }
}, true);

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/equip" component={Equip} />
      <Route path="/champions" component={Champions} />
      <Redirect path="/" to="/equip" />
    </Switch>
  </Router>,
  document.body
);
