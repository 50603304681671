// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html, body{\n  width: 100%;\n  height: 100%;\n  padding: 0;\n  margin: 0;\n}\n\n#_3CkMYV5m7_8TdiTXf-jVyD {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  z-index:9000;\n}\n\n._3-3WDdWMxlUArJ-cEhMuBU{\n  display:block;\n}\n\n#dO-enun7-8WjchnS3R3qJ{\n  height: 100%;\n  overflow: hidden;\n}\n\n._3-3WDdWMxlUArJ-cEhMuBU div[style]{\n  position: relative !important;\n}\n", "",{"version":3,"sources":["webpack://./src/ui/styles/Root.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;EACV,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":["html, body{\n  width: 100%;\n  height: 100%;\n  padding: 0;\n  margin: 0;\n}\n\n#root {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  z-index:9000;\n}\n\n.statsContainer{\n  display:block;\n}\n\n#gameplay-container{\n  height: 100%;\n  overflow: hidden;\n}\n\n.statsContainer div[style]{\n  position: relative !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_3CkMYV5m7_8TdiTXf-jVyD",
	"statsContainer": "_3-3WDdWMxlUArJ-cEhMuBU",
	"gameplay-container": "dO-enun7-8WjchnS3R3qJ"
};
export default ___CSS_LOADER_EXPORT___;
